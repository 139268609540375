export const testimonials = [
  {
    id: 1,
    name: "Dipesh Patel",
    review: "Excellent service and quality! Will definitely use again.",
    rating: 5,
  },
  {
    id: 2,
    name: "Jane Smith",
    review: "Very professional, and the team is highly skilled. Highly recommend!",
    rating: 4,
  },
  {
    id: 3,
    name: "Jane Smith",
    review: "Very professional, and the team is highly skilled. Highly recommend!",
    rating: 4,
  },
  {
    id: 4,
    name: "Jane Smith",
    review: "Very professional, and the team is highly skilled. Highly recommend!",
    rating: 4,
  },
];
